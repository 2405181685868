import React from 'react'

const Home = () => {
  return (
    <div>
       <a href="https://bestphotodigital.com/Bestfront">เข้าสู่ระบบ</a>
       <a href="https://bestphotodigital.com/Bestphotoofficial">admin</a>
    </div>
  )
}

export default Home