import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';


function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>Bestphoto Digital อัพขึ้นให้ได้ซักทีเถอะะะะะ</p>
    //   </header>
    // </div>
    <BrowserRouter>
    <div>
      <Routes>
        <Route path='/' element={<Home/>}/>
      </Routes>
    </div>
    </BrowserRouter>

  );
}

export default App;
